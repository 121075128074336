var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          path: '/dashboard',
          icon: 'ni ni-tv-2 text-primary',
        }}}),(_vm.adminUser)?_c('sidebar-item',{attrs:{"link":{
          name: 'Clients',
          path: '/clients',
          icon: 'ni ni-shop text-primary',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Referrals',
          path: '/referrals',
          icon: 'ni ni-shop text-primary',
        }}}),(_vm.adminUser)?_c('sidebar-item',{attrs:{"link":{
          name: 'Distributors',
          path: '/suppliers',
          icon: 'ni ni-shop text-primary',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Transactions',
          path: '/transactions',
          icon: 'ni ni-money-coins text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Payment Batches',
          path: '/disbursements',
          icon: 'ni ni-money-coins text-primary',
        }}}),(_vm.adminUser)?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          path: '/users',
          icon: 'ni ni-circle-08 text-primary',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Pay@ Reports',
          path: '/payat-reports',
          icon: 'ni ni-single-copy-04 text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Thrive Data',
          path: '/thrive-data',
          icon: 'ni ni-single-copy-04 text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Thrive Offers',
          path: '/thrive-offers',
          icon: 'ni ni-single-copy-04 text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Thrive Payments',
          path: '/thrive-payments',
          icon: 'ni ni-single-copy-04 text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Thrive Disbursements',
          path: '/thrive-disbursements',
          icon: 'ni ni-single-copy-04 text-primary',
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }